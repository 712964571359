#mode-selector {
    margin: 10px;
    display: inline-block;
    position: relative;
    z-index: 10000;

    form {
        display: inline-block;
    }

    input[type="radio"] {
        display: none;
    }

    label {
        display: inline-block;
        background-color: #555;
        padding: 4px 11px;
        border-radius: 2px;
        font-size: 18pt;
        cursor: pointer;
    }

    input[type="radio"]:checked + label {
        background-color: #333;
    }
}

#tabletop-flex-container {
    display: flex;
    flex-flow: column;
    height: 100%;

    .row {
        margin: 3px;
    }

    .row.header {
        flex: 0 1 auto;
    }

    .row.content {
        flex: 1 1 auto;
        margin: 0;
    }

    .row.footer {
        flex: 0 1 auto;
        margin: 10px;
    }

    hr {
        margin: 0;
    }
}

#tabletop-container {
    position: relative;
    height: 100%;

    // Stack all of the canvas layers on top of each other.
    .canvas-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
